  import React, { useEffect, useState } from 'react';
  import '../../Page2/Light-placeholder/Lplacehold.css'
  import './About.css'
  import founder1 from '../../../images/founder1.png'
  import founder2 from '../../../images/founder2.png'
  import keer from '../../../images/keerthana.png'
  import bg1 from '../../../images/bg-text1.svg'
  import bg2 from '../../../images/bg-text2.svg'
  import reva from '../../../images/Revanth.png'
  import wedo from '../../../images/wedo-lap.png'
  import plat1 from '../../../images/react.png'
  import plat2 from '../../../images/ahref.png'
  import plat3 from '../../../images/meta.png'
  import plat4 from '../../../images/ps.png'
  import greybg from '../../../images/grey-text.svg'
  import Tslider from '../Teamslider/Teamslider'
  import polio from '../../../images/polio-img.png'
  import achiv from '../../../images/achiv1.png'
  import CardContainer from '../card/Card'
  import FloatingLabelInputl from '../Light-placeholder/Lplacehold'
  import Footere from '../../Page2/Lfooter/Lfooter'
  import Footerel from '../../Page2/Lfooter/Lfooter'
  import Tslidern from '../Teamslider/Nsliider';
  import { useLocation } from 'react-router-dom';





  const Aboute = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    const location = useLocation();
  //   useEffect(() => {
  //     const lenis = new Lenis({
  //       duration: 1.2,
  //       easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  //     });

  //     function raf(time) {
  //       lenis.raf(time);
  //       ScrollTrigger.update();
  //       requestAnimationFrame(raf);
  //     }

  //     requestAnimationFrame(raf);

  //     const timeline = gsap.timeline({ paused: true });
  //     timeline.fromTo(
  //       '.col-left', // Ensure this class matches the correct element
  //       { y: 0 },
  //       { y: '170vh', duration: 1, ease: 'none' },
  //       0
  //     );

  //     ScrollTrigger.create({
  //       animation: timeline,
  //       trigger: '.section1', // Ensure this class matches the correct element
  //       start: 'top top',
  //       end: 'bottom center',
  //       scrub: true,
  //     });

  //     gsap.to('.box-items', { // Ensure this class matches the correct element
  //       xPercent: -100 * (document.querySelectorAll('.box-items').length - 1),
  //       ease: 'sine.out',
  //       scrollTrigger: {
  //         trigger: '#horizontal', // Ensure this id matches the correct element
  //         pin: true,
  //         scrub: 3,
  //         snap: 1 / (document.querySelectorAll('.box-items').length - 1),
  //         end: `+=${document.querySelector('.section1').offsetWidth}`, // Ensure this class matches the correct element
  //       },
  //     });

  //     return () => {
      
  //       lenis.destroy();
  //     };
  //   }, []);

    

    // useEffect(() => {
    //   const params = new URLSearchParams(location.search);
    //   const section = params.get('section');
    //   if (section) {
    //     const element = document.getElementById(section);
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   }
    // }, [location.search]);
      
    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent the default form submission
    
      // Clear the input fields immediately
      event.target.reset();
    
      // Optionally, reset your input state variables if you're using state
      setInputValueName('');
      setInputValueNumber('');
      setInputValueMessage('');
    
      const formData = new FormData(event.target);
    
      fetch(event.target.action, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          // Optionally, show a success message here
        })
        .catch(error => {
          console.error('Error:', error);
          // Optionally, show an error message here
        });
    };

    const [isFocusedName, setIsFocusedName] = useState(false);
    const [inputValueName, setInputValueName] = useState('');

    const [isFocusedNumber, setIsFocusedNumber] = useState(false);
    const [inputValueNumber, setInputValueNumber] = useState('');

    const [isFocusedMessage, setIsFocusedMessage] = useState(false);
    const [inputValueMessage, setInputValueMessage] = useState('');

    const handleFocusName = () => setIsFocusedName(true);
    const handleBlurName = () => {
      if (inputValueName === '') setIsFocusedName(false);
    };
    const handleChangeName = (e) => setInputValueName(e.target.value);

    const handleFocusNumber = () => setIsFocusedNumber(true);
    const handleBlurNumber = () => {
      if (inputValueNumber === '') setIsFocusedNumber(false);
    };
    const handleChangeNumber = (e) => {
      const newValue = e.target.value;
      // Ensure the value is numeric and limited to 11 digits
      if (/^\d*$/.test(newValue) && newValue.length <= 11) {
          setInputValueNumber(newValue);
      }
  };

    const handleFocusMessage = () => setIsFocusedMessage(true);
    const handleBlurMessage = () => {
      if (inputValueMessage === '') setIsFocusedMessage(false);
    };
    const handleChangeMessage = (e) => setInputValueMessage(e.target.value);

    
    return (
      <div>
        <div className="container-fluid about-main-cont">
          <div className='ab-section1' id='about' data-aos="fade-up"
            data-aos-duration="1300">
            <h3 className='head1'>Hear About</h3>
            <h1 className='head2'>Kriya & Reeths!</h1>

            <p className='head3-p'>Kriya & Reeths is the best digital marketing company in Coimbatore for all your digital marketing needs. As a leading digital marketing firm, we offer specialized services that cover every aspect of digital marketing, designed to enhance your online presence.  </p>
          </div>

          <div className="ab-section2 row">
            <div className='bg1-cont'>
              <img className='bg-txt11' src={bg1} alt="" />
            </div>
            <div className='bg2-cont' >
              <img className='bg-txt2' src={bg2} alt="" />
            </div>
            <div className="leader-head col-12 col-sm-5 " data-aos="fade-right"
              data-aos-duration="1300">
              <div className='leader-sub'>
                <h1>Leadership</h1>
                <p>Kriya & Reeths is led by visionary founders with a passion for digital excellence. Their expertise drives our commitment to delivering top-tier digital marketing solutions in Coimbatore. </p>
              </div>
            </div>
            <div className="col-12 founder-main col-sm-5 " data-aos="fade-left"
              data-aos-duration="1300">
              <div className='founder-box'>

                <img className='founder2' src={founder2} alt="" />
                <img className='founder1' src={founder1} alt="" />
                <img className='keer' src={keer} alt="" />
                <img className='reva' src={reva} alt="" />


              </div>
            </div>
          </div>

          <div className='ab-section3 row'>
            <div className='bg1-cont'>
              <img className='bg-txt11' src={greybg} alt="" />
            </div>
            {/* <div className='col-0 col-xxl-1'></div> */}
            <div className="col-12 col-sm-6  wedo-mg-head" data-aos="zoom-in"
              data-aos-duration="1300">
              <div className='wedo-sub-head'>
                <img className='wedo-img' src={wedo} alt="" />
                <img src={plat1} alt="" className="platforms1" />
                <img src={plat2} alt="" className="platforms2" />
                <img src={plat3} alt="" className="platforms3" />
                <img src={plat4} alt="" className="platforms4" />
              </div>
            </div>
            <div className="col col-12 col-sm-6 " data-aos="fade-left"
              data-aos-duration="1300">
              <div className='wedo-head'>
                <h1 className='we-h1-1'>Mission:</h1>
                <p>To empower businesses to thrive in the digital age by delivering innovative and effective marketing solutions that drive growth, engagement, and lasting brand impact.
                </p>
              </div>
              <div className='wedo-head'>
                <h1 className='we-h1-1'>Vision:
                </h1>
                <p>To be the leading digital marketing agency recognized for our expertise, creativity, and commitment to helping clients achieve their goals in a rapidly evolving digital landscape.</p>
              </div>
            </div>
          </div>

          <div className="ab-section4">
            <div className='bg1-cont'>
              <img className='bg-txt11' src={bg1} alt="" />
            </div>
            <div className='bg2-cont' >
              <img className='bg-txt2' src={bg2} alt="" />
            </div>
            <div className='creativ-head' data-aos="zoom-in"
              data-aos-duration="1300">
              <h1 >Creative Minds</h1>
              <p>Our team combines diverse talents to innovate and deliver compelling digital marketing strategies that captivate and inspire.
              </p>
            </div>
            <div className='tslider' data-aos="zoom-in"
              data-aos-duration="1300">
              {/* <Tslider /> */}
              <Tslidern/>
            </div>
          </div>

          <div className='ab-section5'>
            <div className='bg1-cont'>
              <img className='bg-txt11' src={greybg} alt="" />
            </div>

            <div className="val-head" data-aos="zoom-in"
              data-aos-duration="1300">
              <h1 className='hd1-one'>Our <span className='hd1-two' >Values</span></h1>
              <p>We value excellence, innovation, integrity, collaboration, and community engagement through social activities. </p>
            </div>
            <div className="val-bdy row" data-aos="fade-up"
              data-aos-duration="1300">
              <div className="vbdy-text col-12 col-sm-6  order-2 order-sm-1">
                <div className='vbdy-text-sub '>
                  <h1 className='hd1-two pulsee'>Pulse Polio</h1>
                  <h3>Immunization Drive 2024</h3>
                  <p>We proudly participated as a team in the Pulse Polio Immunisation Drive 2024, reinforcing our commitment to community health and well-being at Kriya Reeths.</p>
                </div>
              </div>

              <div className="vbdy-frame col-12 col-sm-5 order-1 order-sm-2">
                <div className='vbdy-frame-sub'>
                  <img src={polio} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="ab-section6">
            <div className='bg1-cont'>
              <img className='bg-txt11' src={bg1} alt="" />
            </div>
            <div className='bg2-cont' >
              <img className='bg-txt2' src={bg2} alt="" />
            </div>
            <div className="row">

              <div className="achiv-head" data-aos="zoom-in"
                data-aos-duration="1300">
                <h1 className='hd1-two onee-ach'>Our <br />Certified  Team</h1>
                <p>Our Certified Team is committed to excellence, harnessing in-depth industry knowledge to deliver measurable results.
                </p>
              </div>

              <div className="col achiv-card">
                <CardContainer />
              </div>

            </div>
          </div>

          <div className="ab-section7">
            <div className='bg1-cont'>
              <img className='bg-txt11' src={greybg} alt="" />
            </div>
            <div className='community-head'>
              <div className='community-hd1' data-aos="zoom-in"
                data-aos-duration="1300"><h1 className='hd1-two'><span className='hd1-one '>Join Our</span> Community</h1></div>
              <p data-aos="zoom-in"
                data-aos-duration="1300">Give us a call. Our Door is always open! </p>
              <div className='float-input' data-aos="fade-up"
                data-aos-duration="1300">
                <div className='main-input-container-new'>
                <form action="https://script.google.com/macros/s/AKfycbxuNPBVbEb7HV7wtPXtm5qREUJ7ROXNcYA9GDIt44_oaNE754uEawynbIRsM9uwNlfLCg/exec" name='contact-form' onSubmit={handleSubmit} method='post'>
                <div className='input-group-new'>
          <div className={`floating-label-input-name ${isFocusedName || inputValueName ? 'focused-name' : ''}`}>
            <label className="floating-label-name">Name</label>
            <input
              type="text"
              name='your-name'
              value={inputValueName}
              onFocus={handleFocusName}
              onBlur={handleBlurName}
              onChange={handleChangeName}
              className="input-name"
              required
            />
            <div className='bottom-line-name'></div>
          </div>
          <div className={`floating-label-input-number ${isFocusedNumber || inputValueNumber ? 'focused-number' : ''}`}>
            <label className="floating-label-number">Number</label>
            <input
              type='number'
              name='your-number'
              value={inputValueNumber}
              onFocus={handleFocusNumber}
              onBlur={handleBlurNumber}
              onChange={handleChangeNumber}
              className="input-number"
              maxLength="11"
              required
            />
            <div className='bottom-line-number'></div>
          </div>
        </div>

        <div className='message-input-container-new'>
          <div className={`floating-label-input-message ${isFocusedMessage || inputValueMessage ? 'focused-message' : ''}`}>
            <label className="floating-label-message">Message</label>
            <input
              type="text"
              name='your-message'
              value={inputValueMessage}
              onFocus={handleFocusMessage}
              onBlur={handleBlurMessage}
              onChange={handleChangeMessage}
              className="input-message"
            />
            <div className='bottom-line-message'></div>
          </div>
        </div>
        <input type='submit' value='submit' id='submit' className='lcom-but' data-aos="zoom-in"
                data-aos-duration="1300"/>
                </form>
      </div>
              </div>
              
            </div>
          </div>
          
          <Footerel />
          
        </div>

      </div>

    )
  }

  export default Aboute